import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import { postMethod } from "../../API_SERVICES/ApiServices";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import {
  Badge,
  Tooltip,
  IconButton,
  Popover,
  List,
  Box,
  ListItem,
  Typography,
  ListItemText,
  ListItemButton,
  Divider,
} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import {
  format,
  parseISO,
  formatDistanceToNow,
  isYesterday,
  isTomorrow,
  max,
} from "date-fns";

import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Api } from "@mui/icons-material";
import toast from "react-simple-toasts";
import POSTAPI from "../Dashboards/API_Services/ServiceLayer";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
const user = JSON.parse(localStorage.getItem("user"));

const logo = "https://berighthere.com/wp-content/uploads/2021/03/Logo-New.png";

const NavbarContainer = () => {
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };
  const navigate = useNavigate();

  const customBadgeStyles = {
    "& .MuiBadge-badge": {
      backgroundColor: "#fc9200",
      color: "#ffffff",
    },
  };

  const customBadgeStyles2 = {
    "& .MuiBadge-badge": {
      backgroundColor: "#6d6f73",
      color: "#ffffff",
    },
  };
  const customBadgeStyles3 = {
    "& .MuiBadge-badge": {
      backgroundColor: "#1d90ff",
      color: "#ffffff",
    },
  };
  const [countBadge, setCountBadge] = useState(0);
  const [countGuestBadge, setCountGuestBadge] = useState(0);

  const [notificationList, setNotificationList] = useState([]);

  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [passErr1, setPassErr1] = useState("");
  const [passErr2, setPassErr2] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [passwordIsTypePassword, setpasswordIsTypePassword] = useState(true);
  const [cPasswordIsTypePassword, setcPasswordIsTypePassword] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const paramId = useParams();
  const handleClickBadge = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLoadMore = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const userData = JSON.parse(localStorage.getItem("user"));
      const vdata = {
        Booking_done_by_id: userData.id,
        page: page,
      };

      const response = await POSTAPI.postMethodNotoken(
        "api/countClusterReservations",
        "POST",
        vdata
      );
      const data = await response.json();

      if (data.status === "success") {
        // setNotifications((prevNotifications) => [
        //   ...prevNotifications,
        //   ...data.bookings,
        // ]);
        setNotifications((prevNotifications) => {
          const existingIds = new Set(prevNotifications.map((n) => n.id));
          const newNotifications = data.bookings.filter(
            (n) => !existingIds.has(n.id)
          );

          // Return the updated notifications list, including only unique new notifications
          return [...prevNotifications, ...newNotifications];
        });
        // console.log(notifications, "dfdfdf");
        setPage(page + 1);
        setHasMore(data.has_more);
      } else {
        console.error("Failed to load more reservations:", data.message);
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching more reservations:",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  // Helper function to validate date
  const isValidDate = (date) => {
    return !isNaN(new Date(date).getTime());
  };

  // const sortedNotifications = [...notificationList].sort((a, b) => {
  //   const getMostRecentDate = (notification) => {
  //     const dates = [
  //       parseISO(notification.created_at),
  //       parseISO(notification.updated_at),
  //     ];
  //     if (notification.updated_at) {
  //       dates.push(parseISO(notification.updated_at));
  //     }
  //     return max(dates);
  //   };

  //   const dateA = getMostRecentDate(a);
  //   const dateB = getMostRecentDate(b);

  //   return dateB - dateA; // Sort descending
  // });

  const relativeTime = (notification) => {
    if (notification.updated_at !== null) {
      return formatDistanceToNow(parseISO(notification.updated_at), {
        addSuffix: true,
      });
    }

    return formatDistanceToNow(
      max([
        parseISO(notification.created_at),
        parseISO(notification.updated_at),
      ]),
      { addSuffix: true }
    );
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleListItemClick = async (notification) => {
    // console.log(notification,"notificationnotification")
    updateData();
    // console.log("notif", notification?.notification?.id);
    const bookingdata = {
      userId: user.id,
      bookingId: `${notification?.notification?.booking_id}`,
      notif_id: notification?.notification?.id,
      // action_type:2
    };
    const guestdata = {
      userId: user.id,
      guestId: `${notification?.notification?.guest_id}`,
      notif_id: notification?.notification?.id,
    };

    const data = notification.booking_id ? bookingdata : guestdata;
    console.log("vdata", data);
    try {
      // API call
      const response = await POSTAPI.postMethodNotoken(
        "api/edit-cluster-reservation",
        "POST",
        data
      );

      const jsonResponse = await response.json().then((data) => data);

      // Assuming jsonResponse contains the updated notification
      const updatedNotification = jsonResponse;

      // Check and navigate
      if (
        (updatedNotification.status = "success" && updatedNotification.data.id)
      ) {
        if (updatedNotification.data.guest_id != null) {
          window.location.href = `/discover/booking-guest/${notification.id}`;
        } else {
          // console.log("Navigating to booking details...");

          window.location.href = `/discover/booking-details/${notification.id}`;
        }
      } else {
        console.warn("Navigation failed: No valid notification ID");
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user, "userss");
    _getCount();
  }, []);
  useEffect(() => {
    // Initial load
    handleLoadMore();
  }, []);

  const _getCount = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    const vdata = {
      Booking_done_by_id: userData.id,
    };
    console.log("vdata", vdata);
    await POSTAPI.postMethodNotoken(
      "api/countClusterReservations",
      "POST",
      vdata
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          console.log(data.bookings, "datasnot");
          setCountBadge(data.total_unread_booking_notifications_count);
          setCountGuestBadge(data.total_unread_guest_notifications_count);
          setNotificationList(data.bookings);
          //  data.bookings.forEach((value) => {
          //   const message = `${value.username} - ${value.roles} booked`;

          // });
        }
      })
      .catch((error) => {
        console.error("Error fetching properties:", error);
        toast("Error fetching properties. Please try again later.", 2000);
      });
  };

  const updateData = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    const vdata = {
      Booking_done_by_id: userData.id,
    };
    console.log("vdata", vdata);
    await POSTAPI.postMethodNotoken(
      "api/countClusterReservations",
      "POST",
      vdata
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          console.log(data.bookings, "datas");
          setCountBadge(data.total_unread_booking_notifications_count);
          setCountGuestBadge(data.total_unread_guest_notifications_count);
          setNotificationList(data.bookings);
          //  data.bookings.forEach((value) => {
          //   const message = `${value.username} - ${value.roles} booked`;

          // });
        }
      })
      .catch((error) => {
        console.error("Error fetching properties:", error);
        toast("Error fetching properties. Please try again later.", 2000);
      });
  };

  const handleChangePassword = () => {
    setShow(true);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let username = "";
    let role = "";
    let id = "";
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));

      username = user["name"];
      role = user["role_id"];
      id = user["id"];
    }
    let data = {
      password: password1,
      user_id: id,
      role_id: role,
    };

    console.log(data, "datas");

    postMethod("api/password_update", data)
      .then((res) => {
        console.log(res);
        if (res?.data.status === "success") {
          setShow(false);
          setSuccess(true);
        }
        return res.json(); // Parse the response JSON
      })
      // .then((result) => {
      //   console.log(result);
      //   if (result?.data.status === 'success') {
      //     setShow(false);
      //     setSuccess(true);
      //   }
      // })
      .catch((error) => {
        // Handle any errors that occur during the process

        console.error("Error:", error);
        // return error.json()
      });
  };

  const successDone = () => {
    localStorage.clear();
    window.location.reload();
  };

  const onCheckPassword = (e) => {
    let name = e.target.name;

    if (name === "new_password" && e.target.value === "") {
      setPassErr1("Enter New Password");
    }
    if (name === "confirm_password" && e.target.value === "") {
      setPassErr2("Enter Confirm Password");
    }

    if (password1 !== password2) {
      setMatchPassword("Password Not Matched");
    } else {
      console.log("password1-error", password1);
      console.log("password2-error", password2);
    }
  };

  return (
    <div>
      <Navbar
        style={{ backgroundColor: "#F9FAFB", width: "100%", height: "100%" }}
        collapseOnSelect
        expand="lg">
        <Container className="mx-2">
          <Navbar.Brand onClick={() => navigate("/")}>
            <img src={logo} width="250px" height="auto" alt="brand-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <div className="mobile_screen">
            <Navbar.Collapse id="responsive-navbar-nav">
              <div
                className="ml-auto d-flex align-items-center"
                style={{ marginLeft: "auto" }}>
                <div className="mobile_Screen">
                  {" "}
                  <Nav.Link
                    className="mobile_Screen"
                    onClick={() => navigate("/reports")}>
                    Reports<span className="d-md-none  mobile_Screen ">/</span>
                  </Nav.Link>
                </div>

                <Nav className="ml-5 mr-0">
                  <NavDropdown
                    className="text-capitalize fw-bold"
                    style={{ color: "#f9fafb" }}
                    title={user?.roles[0]?.role_name}
                    id="collasible-nav-dropdown">
                    {/* <NavDropdown.Item href="#action/3.3">Profile</NavDropdown.Item>  */}

                    <NavDropdown.Item href="/visitor-management">
                      {" "}
                      Visitor Management{" "}
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      onClick={() => navigate("/reports")}
                      href="/reports"
                      className="system_screen">
                      {" "}
                      Reports{" "}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/book">
                      {" "}
                      Meeting Space Booking{" "}
                    </NavDropdown.Item>

                    <NavDropdown.Item href="#" onClick={handleChangePassword}>
                      {" "}
                      Change Password{" "}
                    </NavDropdown.Item>

                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={handleLogout} href="#action/3.4">
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>

                <Nav
                  className="ml-5 mr-0s mt-2 text-uppercase"
                  style={{ marginRight: "10px" }}>
                  <h6>{user?.name}</h6>{" "}
                </Nav>

                {/* Icons with badges and tooltips */}
                <Nav className="ml-3 d-flex align-items-center">
                  <Tooltip title="Book a meeting space">
                    <Badge
                      badgeContent={countBadge ? countBadge : 0}
                      sx={customBadgeStyles}
                      style={{ marginRight: "10px", cursor: "pointer" }}>
                      <CalendarMonthIcon
                        sx={{ color: "#fc9200" }}
                        onClick={() => {
                          window.location.href = "/book"; // Navigate to the /book page
                        }}
                      />
                    </Badge>
                  </Tooltip>
                  <Tooltip title="Visitor">
                    <Badge
                      badgeContent={countGuestBadge ? countGuestBadge : 0}
                      sx={customBadgeStyles2}
                      style={{ marginRight: "50px", cursor: "pointer" }}>
                      <SupervisedUserCircleIcon
                        color="action"
                        onClick={() => {
                          window.location.href = "/visitor-management";
                        }}
                      />
                    </Badge>
                  </Tooltip>
                  <Tooltip title="Notifications">
                    <Badge
                      badgeContent={countBadge + countGuestBadge}
                      sx={customBadgeStyles3}
                      onClick={handleClickBadge}
                      style={{ marginRight: "10px", cursor: "pointer" }}>
                      <NotificationsActiveIcon sx={{ color: "#1d90ff" }} />
                    </Badge>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}>
                    <List
                      sx={{
                        maxHeight: 400,
                        width: "400px",
                        overflow: "auto",
                        padding: "8px",
                        "& .MuiListItemButton-root": {
                          marginBottom: "2px",
                          padding: "8px 16px",
                          borderRadius: "4px",
                        },
                        "& .MuiListItemText-root": {
                          margin: 0,
                        },
                        "& .MuiDivider-root": {
                          margin: "4px 0",
                        },
                      }}>
                      {notifications.map((notification, index) => {
                        const formattedDate = isValidDate(
                          notification.start_date
                        )
                          ? format(
                              parseISO(notification.start_date),
                              "EEEE, MMM d 'at' h:mm a"
                            )
                          : "Invalid Date";

                        const cancelledDate = isValidDate(
                          notification.updated_at
                        )
                          ? format(
                              parseISO(notification.updated_at),
                              "EEEE, MMM d 'at' h:mm a"
                            )
                          : "N/A";

                        let message = "";
                        let messageTitle = "";
                        let messageColor = "";
                        const isRead = notification.notification?.read === 1;
                        const fontWeight = isRead ? "normal" : "bold";

                        const isGuest =
                          notification.guest_name !== undefined &&
                          notification.guest_name !== null;

                        return (
                          <React.Fragment key={index}>
                            <ListItemButton
                              sx={{
                                backgroundColor:
                                  notification?.notification?.read === 0
                                    ? "#fc92002e"
                                    : "",

                                "&:hover": {
                                  boxShadow:
                                    "inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15)",
                                  cursor: "pointer",
                                  fontWeight: "800",
                                  fontStyle: "normal",
                                },
                              }}
                              onClick={() => {
                                handleListItemClick(notification);
                                // console.log(notification,"nri")
                              }}>
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between">
                                      <Box display="flex" alignItems="center">
                                        {isGuest ? (
                                          <SupervisedUserCircleIcon
                                            sx={{ mr: 1 }}
                                          />
                                        ) : (
                                          <CalendarMonthIcon sx={{ mr: 1 }} />
                                        )}
                                        <Typography
                                          component="span"
                                          sx={{
                                            fontWeight: fontWeight,
                                            color: messageColor,
                                          }}>
                                          {messageTitle}
                                        </Typography>
                                      </Box>
                                      <Box display="flex" alignItems="center">
                                        <Typography
                                          component="span"
                                          sx={{
                                            fontStyle: "italic",
                                            marginLeft: "auto",
                                          }}>
                                          {relativeTime(notification)}
                                        </Typography>
                                        {notification?.notification?.read ===
                                          0 && (
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: "8px",
                                              height: "8px",
                                              borderRadius: "50%",
                                              backgroundColor: "#fc9200",
                                              marginLeft: "8px",
                                            }}></span>
                                        )}
                                      </Box>
                                    </Box>
                                    <Typography
                                      component="span"
                                      sx={{
                                        fontStyle: "italic",
                                        display: "block",
                                        textAlign: "left",
                                        marginTop: "4px",
                                      }}>
                                      {message}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItemButton>
                            <Divider variant="inset" component="li" />
                          </React.Fragment>
                        );
                      })}
                      {/* Load More link */}
                      {hasMore && (
                        <ListItemButton
                          onClick={handleLoadMore}
                          disabled={loading}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "8px 16px",
                            cursor: "pointer",
                            borderTop: "1px solid #ddd",
                            color: "#007bff",
                            textAlign: "center",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}>
                          <Typography component="span">Load More</Typography>
                        </ListItemButton>
                      )}
                    </List>
                  </Popover>
                </Nav>
              </div>
            </Navbar.Collapse>
          </div>

          <div className="system_screen">
            <Navbar.Collapse id="responsive-navbar-nav">
              <div
                className="ml-auto d-flex align-items-center"
                style={{
                  marginLeft: "auto",
                }}>
                <Tooltip title="Notifications">
                  <Badge
                    badgeContent={countBadge + countGuestBadge}
                    sx={customBadgeStyles3}
                    onClick={handleClickBadge}
                    style={{ marginRight: "10px", cursor: "pointer" }}>
                    <NotificationsActiveIcon sx={{ color: "#1d90ff" }} />
                  </Badge>
                </Tooltip>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}>
                  <List
                    sx={{
                      maxHeight: 400,
                      width: "400px",
                      overflow: "auto",
                      padding: "8px",
                      "& .MuiListItemButton-root": {
                        marginBottom: "2px",
                        padding: "8px 16px",
                        borderRadius: "4px",
                      },
                      "& .MuiListItemText-root": {
                        margin: 0,
                      },
                      "& .MuiDivider-root": {
                        margin: "4px 0",
                      },
                    }}>
                    {notifications.length === 0 ? (
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "#888",
                          padding: "16px",
                        }}>
                        No data found
                      </Typography>
                    ) : (
                      notifications.map((notification, index) => {
                        const formattedDate = isValidDate(
                          notification.start_date
                        )
                          ? format(
                              parseISO(notification.start_date),
                              "EEEE, MMM d 'at' h:mm a"
                            )
                          : "Invalid Date";

                        const cancelledDate = isValidDate(
                          notification.updated_at
                        )
                          ? format(
                              parseISO(notification.updated_at),
                              "EEEE, MMM d 'at' h:mm a"
                            )
                          : "N/A";

                        let message = "";
                        let messageTitle = "";
                        let messageColor = "";
                        const isRead = notification.notification?.read === 1;
                        const fontWeight = isRead ? "normal" : "bold";

                        const isGuest =
                          notification.guest_name !== undefined &&
                          notification.guest_name !== null;
                        console.log(user.id);
                        switch (notification?.notification?.action_type) {
                          case 0: // Booking
                            messageTitle = isGuest
                              ? "VISITOR BOOKING"
                              : "MEETING ROOM BOOKING";
                            messageColor = isRead ? "black" : "#fc9200";
                            message = isGuest ? (
                              notification.Booking_done_by_id === user.id ? (
                                <>
                                  You booked{" "}
                                  <span style={{ fontWeight }}>
                                    {
                                      notification.property_details
                                        .property_name
                                    }
                                  </span>{" "}
                                  for the Guest{" "}
                                  <span style={{ fontWeight }}>
                                    {notification.guest_name}
                                  </span>{" "}
                                  on {formattedDate}
                                </>
                              ) : (
                                <>
                                  The{" "}
                                  <span style={{ fontWeight }}>
                                    {notification.roles}
                                  </span>{" "}
                                  <span style={{ fontWeight }}>
                                    {notification.username}
                                  </span>{" "}
                                  booked{" "}
                                  <span style={{ fontWeight }}>
                                    {
                                      notification.property_details
                                        .property_name
                                    }
                                  </span>{" "}
                                  for the Guest on {formattedDate}
                                </>
                              )
                            ) : notification.Booking_done_by_id === user.id ? (
                              <>
                                You booked{" "}
                                <span style={{ fontWeight }}>
                                  {notification.property_details.property_name}
                                </span>{" "}
                                for the (
                                <span style={{ fontWeight }}>
                                  {notification.roles}
                                </span>
                                ){" "}
                                <span style={{ fontWeight }}>
                                  {notification.username}
                                </span>{" "}
                                on {formattedDate}
                              </>
                            ) : (
                              <>
                                The{" "}
                                <span style={{ fontWeight }}>
                                  {notification.roles}
                                </span>{" "}
                                <span style={{ fontWeight }}>
                                  {notification.username}
                                </span>{" "}
                                booked{" "}
                                <span style={{ fontWeight }}>
                                  {notification.property_details.property_name}
                                </span>{" "}
                                on {formattedDate}
                              </>
                            );
                            break;
                          case 1: // Edit
                            messageTitle = isGuest
                              ? "VISITOR BOOKING MODIFIED"
                              : "BOOKING MODIFIED";
                            messageColor = isRead ? "black" : "#fc9200";

                            message = isGuest ? (
                              notification.Booking_done_by_id === user.id ? (
                                <>
                                  You modified{" "}
                                  <span style={{ fontWeight }}>
                                    {
                                      notification.property_details
                                        .property_name
                                    }
                                  </span>{" "}
                                  for the Guest{" "}
                                  <span style={{ fontWeight }}>
                                    {notification.guest_name}
                                  </span>{" "}
                                  on {formattedDate}
                                </>
                              ) : (
                                <>
                                  The{" "}
                                  <span style={{ fontWeight }}>
                                    {notification.roles}
                                  </span>{" "}
                                  <span style={{ fontWeight }}>
                                    {notification.username}
                                  </span>{" "}
                                  modified{" "}
                                  <span style={{ fontWeight }}>
                                    {
                                      notification.property_details
                                        .property_name
                                    }
                                  </span>{" "}
                                  for the Guest on {formattedDate}
                                </>
                              )
                            ) : notification.Booking_done_by_id === user.id ? (
                              <>
                                You modified{" "}
                                <span style={{ fontWeight }}>
                                  {notification.property_details.property_name}
                                </span>{" "}
                                for the (
                                <span style={{ fontWeight }}>
                                  {notification.roles}
                                </span>
                                ){" "}
                                <span style={{ fontWeight }}>
                                  {notification.username}
                                </span>{" "}
                                on {formattedDate}
                              </>
                            ) : (
                              <>
                                The{" "}
                                <span style={{ fontWeight }}>
                                  {notification.roles}
                                </span>{" "}
                                <span style={{ fontWeight }}>
                                  {notification.username}
                                </span>{" "}
                                modified{" "}
                                <span style={{ fontWeight }}>
                                  {notification.property_details.property_name}
                                </span>{" "}
                                on {formattedDate}
                              </>
                            );
                            break;
                          case 2: // Cancel
                            messageTitle = isGuest
                              ? "VISITOR BOOKING CANCELLED"
                              : "BOOKING CANCELLED";
                            messageColor = isRead ? "black" : "#fc9200";
                            message = isGuest ? (
                              notification.Booking_done_by_id === user.id ? (
                                <>
                                  You cancelled{" "}
                                  <span style={{ fontWeight }}>
                                    {
                                      notification.property_details
                                        .property_name
                                    }
                                  </span>{" "}
                                  for the Guest{" "}
                                  <span style={{ fontWeight }}>
                                    {notification.guest_name}
                                  </span>{" "}
                                  on {formattedDate}
                                </>
                              ) : (
                                <>
                                  The{" "}
                                  <span style={{ fontWeight }}>
                                    {notification.roles}
                                  </span>{" "}
                                  <span style={{ fontWeight }}>
                                    {notification.username}
                                  </span>{" "}
                                  cancelled{" "}
                                  <span style={{ fontWeight }}>
                                    {
                                      notification.property_details
                                        .property_name
                                    }
                                  </span>{" "}
                                  for the Guest on {formattedDate}
                                </>
                              )
                            ) : notification.Booking_done_by_id === user.id ? (
                              <>
                                You cancelled{" "}
                                <span style={{ fontWeight }}>
                                  {notification.property_details.property_name}
                                </span>{" "}
                                for the (
                                <span style={{ fontWeight }}>
                                  {notification.roles}
                                </span>
                                ){" "}
                                <span style={{ fontWeight }}>
                                  {notification.username}
                                </span>{" "}
                                on {formattedDate}
                              </>
                            ) : (
                              <>
                                The{" "}
                                <span style={{ fontWeight }}>
                                  {notification.roles}
                                </span>{" "}
                                <span style={{ fontWeight }}>
                                  {notification.username}
                                </span>{" "}
                                cancelled{" "}
                                <span style={{ fontWeight }}>
                                  {notification.property_details.property_name}
                                </span>{" "}
                                on {formattedDate}
                              </>
                            );
                            break;
                          default:
                            messageTitle = "NOTIFICATION";
                            message = "Unknown notification type.";
                        }

                        return (
                          <React.Fragment key={index}>
                            <ListItemButton
                              style={{ background: !isRead && "#fc92002e" }}
                              sx={{
                                backgroundColor: isRead ? "" : "#fc92002e",
                                "&:hover": {
                                  boxShadow:
                                    "inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15)",
                                  cursor: "pointer",
                                  fontWeight: "800",
                                  fontStyle: "normal",
                                },
                              }}
                              onClick={() => {
                                handleListItemClick(notification);
                              }}>
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between">
                                      <Box display="flex" alignItems="center">
                                        {isGuest ? (
                                          <SupervisedUserCircleIcon
                                            sx={{ mr: 1 }}
                                          />
                                        ) : (
                                          <CalendarMonthIcon sx={{ mr: 1 }} />
                                        )}
                                        <Typography
                                          component="span"
                                          sx={{
                                            fontWeight: fontWeight,
                                            color: messageColor,
                                          }}>
                                          {messageTitle}
                                        </Typography>
                                      </Box>
                                      <Box display="flex" alignItems="center">
                                        <Typography
                                          component="span"
                                          sx={{
                                            fontStyle: "italic",
                                            marginLeft: "auto",
                                          }}>
                                          {relativeTime(notification)}
                                        </Typography>
                                        {notification?.notification?.read ===
                                          0 && (
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: "8px",
                                              height: "8px",
                                              borderRadius: "50%",
                                              backgroundColor: "#fc9200",
                                              marginLeft: "8px",
                                            }}></span>
                                        )}
                                      </Box>
                                    </Box>
                                    <Typography
                                      component="span"
                                      sx={{
                                        fontStyle: "italic",
                                        display: "block",
                                        textAlign: "left",
                                        marginTop: "4px",
                                      }}>
                                      {message}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItemButton>
                            <Divider variant="inset" component="li" />
                          </React.Fragment>
                        );
                      })
                    )}
                    {/* Load More link */}
                    {hasMore && (
                      <ListItemButton
                        onClick={handleLoadMore}
                        disabled={loading}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "8px 16px",
                          cursor: "pointer",
                          borderTop: "1px solid #ddd",
                          color: "#007bff",
                          textAlign: "center",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}>
                        <Typography component="span">Load More</Typography>
                      </ListItemButton>
                    )}
                  </List>
                </Popover>

                <Nav className="ml-5 mr-0 system_screent">
                  <NavDropdown
                    className="text-capitalize fw-bold"
                    // style={{ color: "#f9fafb" }}
                    style={{ color: "#9e9e9e4d" }}
                    title={user?.roles[0]?.role_name}
                    id="collasible-nav-dropdown">
                    {/* <NavDropdown.Item href="#action/3.3">Profile</NavDropdown.Item>  */}

                    <NavDropdown.Item href="/visitor-management">
                      {" "}
                      Visitor Management{" "}
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      onClick={() => navigate("/reports")}
                      href="/reports"
                      className="system_screen">
                      {" "}
                      Reports{" "}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/book">
                      {" "}
                      Book Meeting Space{" "}
                    </NavDropdown.Item>

                    <NavDropdown.Divider />

                    <NavDropdown.Item href="/book">
                      <Badge
                        badgeContent={4}
                        sx={customBadgeStyles}
                        style={{ marginRight: "10px", cursor: "pointer" }}>
                        Meeting Room Notification
                        <CalendarMonthIcon color="action" />
                      </Badge>
                    </NavDropdown.Item>

                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/visitor-management">
                      <Badge
                        badgeContent={2}
                        sx={customBadgeStyles2}
                        style={{ cursor: "pointer" }}>
                        Guest Notification
                        <SupervisedUserCircleIcon color="action" />
                      </Badge>
                    </NavDropdown.Item>

                    <NavDropdown.Divider />

                    <NavDropdown.Item href="#" onClick={handleChangePassword}>
                      {" "}
                      Change Password{" "}
                    </NavDropdown.Item>

                    <NavDropdown.Item onClick={handleLogout} href="#action/3.4">
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </div>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>

      <Modal
        centered
        backdrop="static"
        show={success}
        onHide={() => successDone()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
          <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
            <h4 className="text-center">Your password updated successfully!</h4>
            <button
              type="button"
              className="btn btn-sm btn-primary rounded-0 mt-3 w-50"
              onClick={() => successDone()}>
              Close
            </button>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    </div>
  );
};

export default NavbarContainer;
