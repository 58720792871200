import React from "react";
import CounterBox from "../Dashboards/CounterBox";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { modalSlice } from "../Dashboards/ModalSlice";
import { Container, Grid } from "@mui/material";

const HeaderSection = () => {
  const notificationsData = useSelector(
    (state) => state?.modal?.notificationData
  );

  return (
    // <Row className="m-0 p-1">
    //   {notificationsData?.map((data, index) => (
    //     <Col key={index} className="m-0 p-0 h-100 w-100">
    //       <CounterBox
    //         key={index}
    //         id={index}
    //         name={data?.name}
    //         count={data?.count}
    //         value={data?.value}
    //       />
    //     </Col>

    //   ))}
    // </Row>
    <Container
      sx={{
        // backgroundColor: "#fff9db",
        padding: "10px",
        border: "2px solid #fff",
      }}
    >
      <Grid
        container
        rowGap={1}
        columnSpacing={2}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {notificationsData.map((data, index) => (
          <Grid item sm={6} md={3}>
            <CounterBox
              key={index}
              id={index}
              name={data?.name}
              count={data?.count}
              value={data?.value}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HeaderSection;
