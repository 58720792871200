import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialReactTable from "material-react-table";
import {
  Badge,
  Button,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Box, display } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  closeEditModal,
  fullScreenToggler,
  issuesListData,
  modalSlice,
  notificationsData,
  openEditModal,
  openModal,
  screenLoading,
} from "../Dashboards/ModalSlice";
import ModalComponent from "../Dashboards/Modal";
import { getMethod, updateMethod } from "../../API_SERVICES/ApiServices";
import moment from "moment";
import { Autocomplete, Skeleton } from "@mui/material";
import { BsCircleFill } from "react-icons/bs";
import fontAwesomeIcons from "../common/TableIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import "./TableSection.css";
import { calculateEndTime } from "../Dashboards/CreateTicketForm";
import FlagIcon from "@mui/icons-material/Flag";
import "./TableSection.css";
const ISSUE_STATUS_UNASSIGNED = 1;
const ISSUE_STATUS_ASSIGNED = 2;
const ISSUE_STATUS_WORKSTARTED = 3;
const ISSUE_STATUS_RESOLVED = 4;
const ISSUE_STATUS_REOPEN = 5;
const ISSUE_STATUS_CLOSED = 6;
const REOPEN_RESTRICTION_DAYS = 3;

export const calculateActualTimeDifference = (
  end,
  responsed,
  mgStart,
  mgEnd
) => {
  let tempStartDate = new Date(end);
  let tempEndDate = new Date(responsed);
  const [mgStartHours, mgStartMinutes, mgStartSeconds] = mgStart.split(":");
  const [mgEndHours, mgEndMinutes, mgEndSeconds] = mgEnd.split(":");
  // #step-1 end,responsed date
  //end date fixed with office hour and excluding sunday
  //responsed date may be sunday
  // #step-2 adding dates
  //1.stDate === endDate
  // a.add startdate
  // b.add endDate
  //2.stDate === endDate
  // a.add startDate
  // b.add end date if morethan mgEnd time fix with mgEndTime
  //c.add intermediate dates

  // ########################
  // --------START DATE LOGIC
  // FIXING STARTDATE
  tempStartDate =
    tempStartDate.getDay() === 0
      ? new Date(
          tempStartDate.getFullYear(),
          tempStartDate.getMonth(),
          tempStartDate.getDate() - 1,
          +mgEndHours,
          +mgEndMinutes,
          +mgEndSeconds
        )
      : tempStartDate;
  // FIXING OFFICE HOURS
  const expectStartDateMorning = new Date(
    tempStartDate.getFullYear(),
    tempStartDate.getMonth(),
    tempStartDate.getDate(),
    +mgStartHours,
    +mgStartMinutes,
    +mgStartSeconds
  );
  const expectStartDateEvening = new Date(
    tempStartDate.getFullYear(),
    tempStartDate.getMonth(),
    tempStartDate.getDate(),
    +mgEndHours,
    +mgEndMinutes,
    +mgEndSeconds
  );
  tempStartDate =
    tempStartDate < expectStartDateMorning
      ? expectStartDateMorning
      : tempStartDate > expectStartDateEvening
      ? expectStartDateEvening
      : tempStartDate;

  // --------END DATE LOGIC
  // FIXING SUNDAYS
  tempEndDate =
    tempEndDate.getDay() === 0
      ? new Date(
          tempEndDate.getFullYear(),
          tempEndDate.getMonth(),
          tempEndDate.getDate() - 1,
          +mgEndHours,
          +mgEndMinutes,
          +mgEndSeconds
        )
      : tempEndDate;
  // FIXING OFFICE HOURS
  const expectEndDateMorning = new Date(
    tempEndDate.getFullYear(),
    tempEndDate.getMonth(),
    tempEndDate.getDate(),
    +mgStartHours,
    +mgStartMinutes,
    +mgStartSeconds
  );
  const expectEndDateEvening = new Date(
    tempEndDate.getFullYear(),
    tempEndDate.getMonth(),
    tempEndDate.getDate(),
    +mgEndHours,
    +mgEndMinutes,
    +mgEndSeconds
  );
  tempEndDate =
    tempEndDate < expectEndDateMorning
      ? expectEndDateMorning
      : tempEndDate > expectEndDateEvening
      ? expectEndDateEvening
      : tempEndDate;
  // GET DATES
  let dates = [];
  while (tempStartDate <= tempEndDate) {
    //  ADD DATE && ELIMINATE SUNDAYS
    tempStartDate.getDay() === 0
      ? new Date(
          tempStartDate.getFullYear(),
          tempStartDate.getMonth(),
          tempStartDate.getDate() + 1,
          tempEndDate.getHours(),
          tempEndDate.getMinutes(),
          tempEndDate.getSeconds()
        )
      : dates.push(tempStartDate);
    // BREAK LOGIC
    if (
      tempStartDate.getDate() === tempEndDate.getDate() &&
      tempStartDate.getMonth() === tempEndDate.getMonth()
    )
      break;

    // RECURSION LOGIC
    tempStartDate = new Date(
      tempStartDate.getFullYear(),
      tempStartDate.getMonth(),
      tempStartDate.getDate() + 1,
      tempEndDate.getHours(),
      tempEndDate.getMinutes(),
      tempEndDate.getSeconds()
    );
  }
  // ADD END DATE
  dates.length === 1 ? dates.push(tempEndDate) : console.log();
  // CALCULATING TIME BETWEEN DATES
  const firtsDay = new Date(dates[0]);
  const lastDay = new Date(dates[dates.length - 1]);
  let timeTaken = 0;
  const oneWorkingDay =
    new Date(2023, 1, 1, +mgEndHours, +mgEndMinutes, +mgEndSeconds).getTime() -
    new Date(2023, 1, 1, +mgStartHours, +mgStartMinutes, +mgStartSeconds);

  const startFirstDay = new Date(firtsDay);
  const endFirstDay = new Date(
    firtsDay.getFullYear(),
    firtsDay.getMonth(),
    firtsDay.getDate(),
    +mgEndHours,
    +mgEndMinutes,
    +mgEndSeconds
  );
  const startLastDay = new Date(
    lastDay.getFullYear(),
    lastDay.getMonth(),
    lastDay.getDate(),
    +mgStartHours,
    +mgStartMinutes,
    +mgStartSeconds
  );
  const endLastDay = new Date(lastDay);
  if (
    firtsDay.getDate() === lastDay.getDate() &&
    firtsDay.getMonth() === lastDay.getMonth()
  ) {
    timeTaken += new Date(lastDay).getTime() - new Date(firtsDay).getTime();
  } else {
    timeTaken +=
      new Date(endFirstDay).getTime() - new Date(startFirstDay).getTime();
    timeTaken +=
      new Date(endLastDay).getTime() - new Date(startLastDay).getTime();
    if (dates.length > 2) {
      timeTaken += (dates.length - 2) * oneWorkingDay;
    }
  }

  //CALCULATE DAYS,HOURS,MINUTES
  let diffDays = Math.floor(timeTaken / oneWorkingDay); // days
  let diffHrs = Math.floor((timeTaken % oneWorkingDay) / 3600000); // hours
  let diffMins = Math.round(((timeTaken % oneWorkingDay) % 3600000) / 60000); // minutes

  let timeString = `${diffDays}:${diffHrs}:${diffMins}`;
  return timeString;
};

export const TableComponent = ({ tableData, isLoading, setIsLoading }) => {
  const aciveBoxValue = useSelector((state) => state?.modal?.filteredValue);
  const [updatedMins, setUpdatedMins] = useState(0);
  const [formData, setFormData] = useState({
    issue_title: "",
    description: "",
    issue_category_id: "",
    role_id: "",
    property_id: "",
    user_id: "",
    corporate_id: "",
    resolved_by: "",
    resolved_at: "",
    status: "",
  });
  const [show, setShow] = useState(false);
  const [issueId, setIssueId] = useState(null);
  const [canReOpen, setCanReopen] = useState(true);
  const [isAlreadyOpened, setIsAlreadyOpened] = useState(false);
  const [isSameActionTaken, setIsSameActionTaken] = useState(false);

  const [issuesDetailsData, setIssuesDetailsData] = useState({});
  const [issueCategoryList, setIssueCategoryList] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, //customize the default page size
  });
  const dispatch = useDispatch();

  const modalClicked = useSelector((state) => state?.modal?.isModalClicked);
  const isFullScreen = useSelector((state) => state?.modal?.fullScreenToggle);
  const loading = useSelector((state) => state?.modal?.loading);
  const editModalClicked = useSelector(
    (state) => state?.modal?.isEditModalClicked
  );
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    getIssueCategories();
  }, []);

  const getIssueCategories = async () => {
    const controller = new AbortController();

    await getMethod("issue-management/api/v1/issue-category", controller.signal)
      .then((res) => {
        if (res?.data?.status) {
          let names = res?.data?.data?.map((n) => n.issue_category_name);
          setIssueCategoryList(names);
        }
      })
      .catch((err) => console.log(err));
    return () => {
      controller.abort();
    };
  };

  const issueDetails = async (id) => {
    const controller = new AbortController();
    dispatch(screenLoading(true));

    getMethod("issue-management/api/v1/issue/" + id, controller.signal).then(
      (res) => {
        if (res?.data?.status) {
          dispatch(screenLoading(false));
          const {
            issue_title,
            description,
            issue_category_id,
            role_id,
            property_id,
            user_id,
            corporate_id,
            resolved_by,
            resolved_at,
            status,
          } = res?.data?.data;

          setFormData({
            ...formData,
            issue_title,
            description,
            issue_category_id,
            role_id,
            property_id,
            user_id,
            corporate_id,
            resolved_by,
            resolved_at,
            status,
          });

          setIssuesDetailsData(res?.data?.data);
        } else {
          alert(res?.data?.message);
        }
      }
    );
    return () => {
      controller.abort();
    };
  };

  const handleClose = () => dispatch(closeEditModal());
  const handleShow = () => dispatch(openEditModal());

  const EditControllerBox = (id) => {
    setIssueId(id);
    handleShow();
    issueDetails(id);
  };

  const issueStatusMaster = [
    { id: 1, label: "Open" },
    { id: 2, label: "Assigned To Staff" },
    { id: 3, label: "In Progress" },
    { id: 4, label: "Resolved" },
    { id: 5, label: "Re-Open" },
    { id: 6, label: "Close" },
  ];

  const issueStatusColor = (statusId) => {
    switch (statusId) {
      case 1:
        return "primary";
      case 2:
        return "warning";
      case 3:
        return "warning";
      case 4:
        return "success";
      case 5:
        return "warning";
      case 6:
        return "success";
      case 7:
        return "danger";

      default:
        break;
    }
  };
  const issueActionStatusName = (statusId) => {
    switch (statusId) {
      case 1:
        return "Unassigned Tickets";
      case 2:
        return "Assigned To Staff";
      case 3:
        return "Work Started";
      case 4:
        return "Resolved";
      case 5:
        return "Re-Open";
      case 6:
        return "Closed";

      default:
        break;
    }
  };

  const issueStatusName = (statusId) => {
    switch (statusId) {
      case 1:
        return "Pending";
      case 2:
        return "In Progress";
      case 3:
        return "In Progress";
      case 4:
        return "Completed";
      case 5:
        return "In Progress";
      case 6:
        return "Completed";

      default:
        break;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleIssueChange = (e) => {
    const { name, value } = e.target;
    // return setFormData({ ...formData, [name]: value });
    // ### RE-OPEN LOGIC INSIDE RESOLVED
    // if (value == 5) {
    //   const resolvedDate = new Date(
    //     issuesDetailsData?.issue_action[2]?.created_at
    //   );
    //   const nextdate = new Date(
    //     resolvedDate.getFullYear(),
    //     resolvedDate.getMonth(),
    //     resolvedDate.getDate() + 1,
    //     resolvedDate.getHours(),
    //     resolvedDate.getMinutes(),
    //     resolvedDate.getSeconds()
    //   );
    //   let finalDate;
    //   const responseArray = issuesDetailsData?.issue_action?.flatMap((ele) =>
    //     ele.issue_status == 2 ? ele : []
    //   );

    //   if (nextdate.getDay() === 0) {
    //     finalDate = new Date(
    //       nextdate.getFullYear(),
    //       nextdate.getMonth(),
    //       nextdate.getDate() + 1,
    //       nextdate.getHours(),
    //       nextdate.getMinutes(),
    //       nextdate.getSeconds()
    //     );
    //   } else {
    //     finalDate = nextdate;
    //   }
    //   if (responseArray[1]) {
    //     setIsAlreadyOpened(true);
    //     return setTimeout(() => setIsAlreadyOpened(false), 3000);
    //   }
    //   if (new Date() < finalDate) {
    //     setFormData({ ...formData, [name]: value });
    //   } else {
    //     setCanReopen(false);
    //     setTimeout(() => setCanReopen(true), 3000);
    //   }
    // } else {
    //   setFormData({ ...formData, [name]: value });
    // }
    // ### RE-OPEN LOGIC INSIDE CLOSED
    if (value == ISSUE_STATUS_REOPEN) {
      // console.log("laksh-re-open", issuesDetailsData.issue_action);
      // #1.find resolved status array
      const apiResolvedDateArray = issuesDetailsData.issue_action.filter(
        (issue) => Number(issue.issue_status) === ISSUE_STATUS_RESOLVED
      );
      // #2.find last resolved date
      const apiResolvedDate =
        apiResolvedDateArray[apiResolvedDateArray.length - 1]?.created_at;
      // #3.allow only if its in range else shows error
      // console.log("laksh-apiResolvedDate", apiResolvedDate);
      const resolvedDate = new Date(apiResolvedDate);
      // console.log("laksh-calculate", resolvedDate);
      let nextdate = new Date(
        resolvedDate.getFullYear(),
        resolvedDate.getMonth(),
        resolvedDate.getDate(),
        resolvedDate.getHours(),
        resolvedDate.getMinutes(),
        resolvedDate.getSeconds()
      );
      for (let index = 1; index < REOPEN_RESTRICTION_DAYS + 1; index++) {
        nextdate = new Date(
          resolvedDate.getFullYear(),
          resolvedDate.getMonth(),
          resolvedDate.getDate() + index,
          resolvedDate.getHours(),
          resolvedDate.getMinutes(),
          resolvedDate.getSeconds()
        );
        if (nextdate.getDay() === 0) {
          nextdate = new Date(
            nextdate.getFullYear(),
            nextdate.getMonth(),
            nextdate.getDate() + 1,
            nextdate.getHours(),
            nextdate.getMinutes(),
            nextdate.getSeconds()
          );
        }
        // console.log("laksh-array", index, nextdate);
      }
      // console.log("laksh-finalDate", nextdate, resolvedDate);

      if (new Date() < nextdate) {
        setFormData({ ...formData, [name]: value });
      } else {
        setCanReopen(false);
        setTimeout(() => setCanReopen(true), 3000);
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const [remainingTime, setRemainingTime] = useState(null);
  const [textColor, setTextColor] = useState("");

  useEffect(() => {
    //do something when the pagination state changes
  }, [pagination.pageIndex, pagination.pageSize]);

  const columns = useMemo(
    () => [
      //ID
      {
        accessorKey: "isDelayed",
        header: "",
        size: "10",
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          return (
            <>
              <BsCircleFill
                size="1.5em"
                style={{
                  color: cell?.row?.original?.isDelayed
                    ? "red"
                    : cell?.row?.original?.status == 1 ||
                      cell?.row?.original?.status == 5
                    ? "dodgerblue"
                    : cell?.row?.original?.status == 2 ||
                      cell?.row?.original?.status == 3
                    ? "orange"
                    : cell?.row?.original?.status == 4 ||
                      cell?.row?.original?.status == 6
                    ? "green"
                    : cell?.row?.original?.isDelayed ?? "red",
                }}
              />
            </>
          );
        },
      },
      //ISSUE CODE
      {
        accessorKey: "issue_code",
        header: "Ticket No",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return <b>{cell.getValue()}</b>;
        },
      },
      //SLA
      {
        accessorKey: "issue_category_id",
        header: "Response Time",
        enableColumnFilter: false,

        size: "10",

        Cell: ({ cell }) => {
          // let { delayDays, delayHours, delayMinutes, nonDelayDays, nonDelayHours, nonDelayMinutes } = cell?.row?.original.delayResponse;
          // console.log(delayDays, delayHours, delayMinutes, nonDelayDays, nonDelayHours, nonDelayMinutes);
          const rowData = cell?.row?.original;
          const assigendArray = rowData.issue_action.filter(
            (issue) => issue.issue_status === 2
          );
          let timeString = calculateActualTimeDifference(
            new Date(rowData?.created_at),
            new Date(assigendArray[0]?.created_at),
            rowData?.issue_category?.mg_start_time,
            rowData?.issue_category?.mg_end_time
          );
          timeString = assigendArray[1]
            ? calculateActualTimeDifference(
                new Date(rowData?.created_at),
                new Date(assigendArray[0]?.created_at),
                rowData?.issue_category?.mg_start_time,
                rowData?.issue_category?.mg_end_time
              )
            : timeString;
          const [actualDays, actualHours, actualMins] = timeString.split(":");
          const { isItDelay, delayTime } = rowData?.timeStringResponse;
          const [days, hours, minutes] = delayTime.split(":");
          return (
            <>
              {assigendArray?.length > 0 ? (
                <>
                  <div>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <span>
                        <span
                          style={{
                            display: actualDays == 0 ? "none" : "inline-block",
                          }}
                        >
                          {actualDays} {actualDays > 1 ? "Days" : "Day"}
                        </span>
                        <span
                          style={{
                            display: actualHours == 0 ? "none" : "block",
                          }}
                        >
                          {actualHours} {actualHours > 1 ? "Hrs" : "Hr"}
                        </span>{" "}
                        <span>
                          {/* style={{ display: minutes == 0 ? "none" : "block" }} */}
                          {actualMins} {actualMins > 1 ? "Mins" : "Min"}
                        </span>
                      </span>
                      <span style={{ display: "inline-block" }}>
                        <FlagIcon color="info" />
                      </span>
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <span style={{ color: isItDelay ? "red" : "green" }}>
                    <span style={{ display: days == 0 ? "none" : "block" }}>
                      {days} {days > 1 ? "Days" : "Day"}
                    </span>
                    <span style={{ display: hours == 0 ? "none" : "block" }}>
                      {hours} {hours > 1 ? "Hrs" : "Hr"}
                    </span>{" "}
                    <span>
                      {/* style={{ display: minutes == 0 ? "none" : "block" }} */}
                      {minutes} {minutes > 1 ? "Mins" : "Min"}
                    </span>
                  </span>
                </>
              )}

              {rowData.responseSLAStatic.isHours === 1 ? (
                <>
                  <span style={{ display: "block", color: "orange" }}>
                    (
                    <span
                      style={{
                        display:
                          rowData?.responseSLAStatic?.hours == 0
                            ? "none"
                            : "inline-block",
                      }}
                    >
                      {rowData?.responseSLAStatic?.hours}
                      {rowData?.responseSLAStatic?.hours > 1
                        ? "Hrs"
                        : "Hr"}{" "}
                    </span>{" "}
                    <span
                      style={{
                        display:
                          rowData?.responseSLAStatic?.minutes == 0
                            ? "none"
                            : "inline-block",
                      }}
                    >
                      {rowData?.responseSLAStatic?.minutes}
                      {rowData?.responseSLAStatic?.minutes > 1
                        ? "Mins"
                        : "Min"}{" "}
                    </span>
                    )
                  </span>
                </>
              ) : (
                <>
                  <span style={{ display: "block", color: "orange" }}>
                    (
                    <span
                      style={{
                        display:
                          rowData?.responseSLAStatic?.period == 0
                            ? "none"
                            : "inline-block",
                      }}
                    >
                      {rowData?.responseSLAStatic?.period}
                      {rowData?.responseSLAStatic?.period > 1
                        ? "Days"
                        : "Day"}{" "}
                    </span>{" "}
                    )
                  </span>
                </>
              )}
            </>
          );
        },
      },
      //REMAININMG TIME
      {
        accessorKey: "resolved_by",
        header: "Resolution Time",
        size: "10",
        enableColumnFilter: false,

        Cell: ({ cell }) => {
          // let { delayDays, delayHours, delayMinutes, nonDelayDays, nonDelayHours, nonDelayMinutes } = cell?.row?.original.delayResponse;
          // console.log(delayDays, delayHours, delayMinutes, nonDelayDays, nonDelayHours, nonDelayMinutes);
          const rowData = cell?.row?.original;
          const responsedArray = rowData.issue_action.filter(
            (issue) => issue.issue_status === 4
          );
          let timeString = calculateActualTimeDifference(
            new Date(rowData?.created_at),
            new Date(responsedArray[0]?.created_at),
            rowData?.issue_category?.mg_start_time,
            rowData?.issue_category?.mg_end_time
          );
          timeString = responsedArray[1]
            ? calculateActualTimeDifference(
                new Date(rowData?.created_at),
                new Date(responsedArray[0]?.created_at),
                rowData?.issue_category?.mg_start_time,
                rowData?.issue_category?.mg_end_time
              )
            : timeString;
          const [actualDays, actualHours, actualMins] = timeString.split(":");
          const { isItDelay, delayTime } = rowData?.timeStringResolution;
          const [days, hours, minutes] = delayTime.split(":");
          return (
            <>
              <span>
                {rowData?.isIssueAssigned === true ? (
                  <>
                    {responsedArray.length > 0 ? (
                      <>
                        <div>
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span>
                              <span
                                style={{
                                  display:
                                    actualDays == 0 ? "none" : "inline-block",
                                }}
                              >
                                {actualDays} {actualDays > 1 ? "Days" : "Day"}
                              </span>
                              <span
                                style={{
                                  display: actualHours == 0 ? "none" : "block",
                                }}
                              >
                                {actualHours} {actualHours > 1 ? "Hrs" : "Hr"}
                              </span>{" "}
                              <span>
                                {/* style={{ display: minutes == 0 ? "none" : "block" }} */}
                                {actualMins} {actualMins > 1 ? "Mins" : "Min"}
                              </span>
                            </span>
                            <span style={{ display: "inline-block" }}>
                              <FlagIcon color="info" />
                            </span>
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <span style={{ color: isItDelay ? "red" : "green" }}>
                          <span
                            style={{ display: days == 0 ? "none" : "block" }}
                          >
                            {days} {days > 1 ? "Days" : "Day"}
                          </span>
                          <span
                            style={{ display: hours == 0 ? "none" : "block" }}
                          >
                            {hours} {hours > 1 ? "Hrs" : "Hr"}
                          </span>{" "}
                          <span>
                            {minutes} {minutes > 1 ? "Mins" : "Min"}
                          </span>
                        </span>
                      </>
                    )}

                    {rowData.resolutionSLAStatic.isHours === 1 ? (
                      <>
                        <span style={{ display: "block", color: "orange" }}>
                          (
                          <span
                            style={{
                              display:
                                rowData?.resolutionSLAStatic?.hours == 0
                                  ? "none"
                                  : "inline-block",
                            }}
                          >
                            {rowData?.resolutionSLAStatic?.hours}
                            {rowData?.resolutionSLAStatic?.hours > 1
                              ? "Hrs"
                              : "Hr"}{" "}
                          </span>{" "}
                          <span
                            style={{
                              display:
                                rowData?.resolutionSLAStatic?.minutes == 0
                                  ? "none"
                                  : "inline-block",
                            }}
                          >
                            {rowData?.resolutionSLAStatic?.minutes}
                            {rowData?.resolutionSLAStatic?.minutes > 1
                              ? "Mins"
                              : "Min"}{" "}
                          </span>
                          )
                        </span>
                      </>
                    ) : (
                      <>
                        <span style={{ display: "block", color: "orange" }}>
                          (
                          <span
                            style={{
                              display:
                                rowData?.resolutionSLAStatic?.period == 0
                                  ? "none"
                                  : "inline-block",
                            }}
                          >
                            {rowData?.resolutionSLAStatic?.period}
                            {rowData?.resolutionSLAStatic?.period > 1
                              ? "Days"
                              : "Day"}{" "}
                          </span>{" "}
                          )
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <span>NA</span>
                  </>
                )}
              </span>
            </>
          );
        },
      },
      // Reported By
      {
        accessorKey: "user.name",
        header: "Reported By",
        // enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          const corporate = cell?.row?.original?.corporate;
          const user = cell?.row?.original?.user;
          const roleId = cell?.row?.original?.role_id;
          return (
            <>
              <span className="text-wrap">
                {roleId == 6 && user === null ? corporate?.name : user?.name}
              </span>
              <br />
              <span style={{ fontSize: "8px", color: "orange" }}>
                {roleId == 5
                  ? "(Member)"
                  : roleId == 10
                  ? "(MG-Employee)"
                  : roleId == 6
                  ? "(Corporate)"
                  : roleId == 8
                  ? "(Sales Executive)"
                  : "(Employee)"}
              </span>
            </>
          );
        },
      },
      // Report Date
      {
        accessorKey: "created_at",
        header: "Report Date",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return (
            <>
              <span>{moment(cell.getValue()).format("DD-MMM-YYYY")}</span>
              <br />
              <span>{moment(cell.getValue()).format("hh:mm A")}</span>
            </>
          );
        },
      },
      // Center
      {
        accessorKey: "property.property_name",
        header: "Center",
        // enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return <span className="text-wrap">{cell.getValue()}</span>;
        },
      },
      // Issue Reported
      {
        accessorKey: "issue_title",
        header: "Issue Reported",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return <span className="text-wrap">{cell.getValue()}</span>;
        },
      },
      // Issue Category
      {
        accessorKey: "issue_category.issue_category_name",
        header: "Issue Category",
        size: "30",
        // filterVariant: "select",
        // filterSelectOptions: issueCategoryList,
        Cell: ({ cell }) => {
          return <span className="text-wrap">{cell.getValue()}</span>;
        },
      },
      // status
      {
        accessorKey: "status",
        header: "Status",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return (
            <span key={cell?.row?.original?.id} className="text-wrap">
              {issueActionStatusName(cell.getValue())}
            </span>
          );
        },
      },
      // Updated Date
      {
        accessorKey: "updated_at",
        header: "Updated Date",
        enableColumnFilter: false,

        size: "30",
        Cell: ({ cell }) => {
          return (
            <>
              <span>{moment(cell.getValue()).format("DD-MMM-YYYY")}</span>
              <br />
              <span>{moment(cell.getValue()).format("hh:mm A")}</span>
            </>
          );
        },
      },
      // Assigned
      {
        accessorKey: "property.contact_person",
        header: "Assigned",
        enableColumnFilter: false,

        size: "30",
        fontSize: "11px",
        Cell: ({ cell }) => {
          return <span className="text-wrap">{cell.getValue()}</span>;
        },
      },
    ],
    []
  );

  const handleEditFormSubmit = (e) => {
    e.preventDefault();
    // guard function
    // RESTRICT UPDATING STATUS WITH SAME STATUS AND ALLOW MULTIPLE IN-PROGRESS UPDATE
    if (issuesDetailsData.status === formData.status && formData.status !== 3) {
      setIsSameActionTaken(true);
      return setTimeout(() => setIsSameActionTaken(false), 3000);
    }
    const data = { ...formData, issue_status: formData?.status };
    if (formData.status == 5) {
      const apiResponseEndTime = calculateEndTime(
        new Date().toISOString(), //2023, 1, 3, 19, 30, 0
        issuesDetailsData?.issue_category?.is_response_hour,
        issuesDetailsData?.issue_category?.response_period,
        issuesDetailsData?.issue_category?.mg_start_time,
        issuesDetailsData?.issue_category?.mg_end_time
      );
      const apiresolutionEndTime = calculateEndTime(
        new Date().toISOString(), //2023, 1, 3, 19, 30, 0
        issuesDetailsData?.issue_category?.is_resolution_hour,
        issuesDetailsData?.issue_category?.resolution_period,
        issuesDetailsData?.issue_category?.mg_start_time,
        issuesDetailsData?.issue_category?.mg_end_time
      );
      data.response_end_time = apiResponseEndTime.toISOString();
      data.resolution_end_time = apiresolutionEndTime.toISOString();
      // console.log('laksh', new Date(), apiResponseEndTime, apiresolutionEndTime);
    }

    // console.log("submitData", data);
    // return;
    updateMethod("issue-management/api/v1/issue/" + issueId, data).then(
      (res) => {
        console.log(res?.status)
        
        if (res?.status) {
          handleClose();
          
          setFormData({
            issue_title: "",
            description: "",
            issue_category_id: "",
            role_id: "",
            property_id: "",
            user_id: "",
            corporate_id: "",
            resolved_by: "",
            resolved_at: "",
            status: "",
            action: "",
            issue_status: "",
          });
        }
      }
    );
  };

  function getIssueStatus(statusCode) {
    if (statusCode == "1") {
      return "Open";
    }
    if (statusCode == "2") {
      return "Assigned To Staff";
    }
    if (statusCode == "3") {
      return "In Progress";
    }
    if (statusCode == "4") {
      return "Resolved";
    }
    if (statusCode == "6") {
      return "Close";
    }
    if (statusCode == "5") {
      return "Re-Open";
    }
  }
  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      setUpdatedMins(updatedMins + 1);
    }, 60000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [updatedMins]);
  useEffect(() => {
    setUpdatedMins(0);
  }, [aciveBoxValue]);
  console.log(
    "form",
    issuesDetailsData,
    new Date().getTime() - new Date(issuesDetailsData.updated_at).getTime() <
      3 * 24 * 60 * 60 * 1000
  );
  return (
    <>
      {!loading && (
        <>
          <MaterialReactTable
            onIsFullScreenChange={() =>
              dispatch(fullScreenToggler(!isFullScreen))
            }
            columns={columns}
            data={tableData}
            enableColumnActions={false}
            enableColumnFilters={true}
            enableColumnFilterModes={false}
            enableHiding={false}
            enableSorting={false}
            enableDensityToggle={false}
            muiTableBodyRowProps={({ row }) => ({
              onClick: () => EditControllerBox(row?.original?.id),
              sx: { cursor: "pointer" },
            })}
            renderTopToolbarCustomActions={({ table }) => (
              <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
                <Button
                  style={{
                    marginTop: "3px",
                    backgroundColor: "#FAA619",
                    border: "none",
                  }}
                  className="rounded-1"
                  onClick={() => {
                    dispatch(openModal());
                  }}
                >
                  Create Ticket
                </Button>
                <span style={{ alignSelf: "center", color: "#ccc" }}>
                  Updated {updatedMins} min ago ...
                </span>
              </Box>
            )}
            initialState={{
              showGlobalFilter: false,
              density: "compact",
            }}
            onPaginationChange={setPagination}
            state={{ pagination }}
            icons={{
              FullscreenIcon: () => (
                <FontAwesomeIcon icon={!isFullScreen ? faExpand : faCompress} />
              ),
            }}
          />
          <ModalComponent isOpen={modalClicked} />
          {!loading && (
            <Modal
              size="fullscreen"
              backdrop="static"
              show={editModalClicked}
              onHide={handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Ticket</Modal.Title>
              </Modal.Header>
              <form
                onSubmit={handleEditFormSubmit}
                className="w-100 create-ticket-form"
              >
                {/* New Edit is implemeting here */}
                <Row>
                  <div className="form-group col-md-6 col-sm-12">
                    <label className="form-label" htmlFor="issue_category_id">
                      Issue Category <span className="text-danger">*</span>
                    </label>
                    <input
                      readOnly
                      id="user_id"
                      name={"user_id"}
                      value={
                        issuesDetailsData?.issue_category?.issue_category_name
                      }
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group col-md-6 col-sm-12">
                    <label className="form-label" htmlFor="role_id">
                      Select User Type <span className="text-danger">*</span>
                    </label>
                    <input
                      readOnly
                      id="user_id"
                      name={"user_id"}
                      value={
                        issuesDetailsData?.role_id == 10
                          ? "MG-Employee"
                          : issuesDetailsData?.role_id == 5
                          ? "Member"
                          : issuesDetailsData?.role_id == 6
                          ? "Corporate"
                          : issuesDetailsData?.role_id == 8
                          ? "Sales Executive"
                          : "Employee"
                      }
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {formData?.role_id == 7 && (
                    <div className="form-group col-md-6 col-sm-12">
                      <label className="form-label" htmlFor="corporate_id">
                        Select Corporate <span className="text-danger">*</span>
                      </label>
                      <input
                        id="user_id"
                        name={"user_id"}
                        value={issuesDetailsData?.corporate?.name}
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                  )}
                  {formData?.role_id == 7 && (
                    <>
                      <div className="form-group col-md-6 col-sm-12">
                        <label className="form-label" htmlFor="property_id">
                          Select Corporate Property{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          readOnly
                          id="user_id"
                          name={"user_id"}
                          value={issuesDetailsData?.property?.property_name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-6 col-sm-12">
                        <label className="form-label" htmlFor="user_id">
                          (Corporate Emp)Issue Raised By{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          readOnly
                          id="user_id"
                          name={"user_id"}
                          value={issuesDetailsData?.user?.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </>
                  )}
                  {formData?.role_id != 7 && (
                    <>
                      <div className="form-group col-md-6 col-sm-12">
                        <label className="form-label" htmlFor="user_id">
                          Issue Raised for{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          readOnly
                          id="user_id"
                          name={"user_id"}
                          value={
                            issuesDetailsData?.user?.name ??
                            issuesDetailsData?.corporate?.name
                          }
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="form-group col-md-6 col-sm-12">
                        <label className="form-label" htmlFor="property_id">
                          Property Name <span className="text-danger">*</span>
                        </label>
                        <input
                          readOnly
                          id="user_id"
                          name={"user_id"}
                          value={issuesDetailsData?.property?.property_name}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )}

                  <div className="form-group col-md-6 col-sm-12">
                    <label className="form-label" htmlFor="issue_title">
                      Issue Tittle <span className="text-danger">*</span>
                    </label>
                    <input
                      id="issue_title"
                      name="issue_title"
                      value={issuesDetailsData?.issue_title}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-6 col-sm-12">
                    <label className="form-label" htmlFor="description">
                      Issue Description
                    </label>
                    <textarea
                      rows="2"
                      id="description"
                      name="description"
                      value={issuesDetailsData.description}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-6 col-sm-12">
                    <label className="form-label" htmlFor="status">
                      Action
                    </label>
                    {issuesDetailsData?.status == 6 ? (
                      <div>
                        {new Date().getTime() -
                          new Date(issuesDetailsData.updated_at).getTime() <
                        3 * 24 * 60 * 60 * 1000 ? (
                          /* Check if less than 3 days since status 6 */
                          <select
                            id="status"
                            name="status"
                            value={formData.status}
                            onChange={handleIssueChange}
                            required
                          >
                            {[5, 6].map((val) => (
                              <option key={val} value={val}>
                                {getIssueStatus(val)}
                              </option>
                            ))}
                          </select>
                        ) : (
                          /* If more than 3 days since status 6, display closed status */
                          <input
                            type="text"
                            value={issueActionStatusName(
                              issuesDetailsData?.status
                            )}
                          />
                        )}
                      </div>
                    ) : (
                      <>
                        <select
                          id="status"
                          name="status"
                          value={formData.status}
                          onChange={handleIssueChange}
                          required
                          // disabled={
                          //   user?.roles[0]?.role_name === "Sales Executive"
                          //     ? true
                          //     : false
                          // }
                        >
                          {[1, 2, 3, 4, 5, 6].map((val) => {
                            const shouldDisplay =
                              issuesDetailsData.status == 4
                                ? // condition for resolve show close and reopen
                                  val == Number(issuesDetailsData.status) ||
                                  val == Number(issuesDetailsData.status) + 1 ||
                                  val == Number(issuesDetailsData.status) + 2
                                : issuesDetailsData.status == 5
                                ? // condtion for reopen show assigned to staff
                                  val == 2 || val == issuesDetailsData.status
                                : user?.roles[0]?.role_name ===
                                    "Sales Executive" &&
                                  (issuesDetailsData.status == 2 ||
                                    issuesDetailsData.status == 3 ||
                                    issuesDetailsData?.status == 1)
                                ? val == issuesDetailsData.status
                                : user?.roles[0]?.role_name ===
                                    "Sales Executive" &&
                                  issuesDetailsData.status == 4
                                ? val == 6 || val == 5 // condition for other status values show only next step
                                : val == Number(issuesDetailsData.status) ||
                                  val == Number(issuesDetailsData.status) + 1;
                            return (
                              <option
                                key={val}
                                value={val}
                                style={{
                                  display: shouldDisplay ? "block" : "none",
                                }}
                                // style={{
                                //   display:
                                //     val - 1 > issuesDetailsData.status
                                //       ? "none"
                                //       : "block",
                                // }}
                              >
                                {getIssueStatus(val)}
                              </option>
                            );
                          })}
                          {/* {issueStatusMaster?.map((status, index) => (
                            <option
                              disabled={status?.id === 1}
                              key={index}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  status: Number(e.target.value),
                                })
                              }
                              value={status?.id}
                            >
                              {status?.label}
                            </option>
                          ))} */}
                        </select>
                        <p 
                          className="Reopen-error-text"
                          style={{
                            display: canReOpen === true ? "none" : "block",
                          }}
                        >
                          You can not Re-open.It is expired(
                          <span style={{ fontSize: "14px" }}>
                            Re-open after {REOPEN_RESTRICTION_DAYS} days only
                          </span>
                          )
                        </p>
                        <p
                          className="Reopen-error-text"
                          style={{
                            display:
                              isAlreadyOpened === false ? "none" : "block",
                          }}
                        >
                          You can not Reopen.It is Already Reopened
                        </p>
                        <label className="form-label" htmlFor="st">
                          Status
                        </label>
                        <h4
                          className={`text-${issueStatusColor(
                            issuesDetailsData?.status
                          )}`}
                          id="st"
                        >
                          {issueStatusName(issuesDetailsData?.status)}
                        </h4>
                      </>
                    )}
                  </div>
                  {(formData?.status != 1 ||
                    user?.roles[0]?.role_name === "Sales Executive") && (
                    <div className="form-group col-md-6 col-sm-12">
                      <label className="form-label" htmlFor="action">
                        {formData?.status == 5
                          ? "Reason for Re-Open"
                          : "Issue Action"}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        rows="2"
                        id="action"
                        name="action"
                        value={formData.action}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  )}
                  {formData?.status == 2 && (
                    <div className="form-group col-md-6 col-sm-12">
                      <label className="form-label">
                        Sending notification
                        <h5>
                          <i>
                            {
                              issuesDetailsData?.issue_category
                                ?.service_department?.service_department_email
                            }
                            &nbsp; and &nbsp;
                            {issuesDetailsData?.property?.contact_email ??
                              issuesDetailsData?.property?.contact_person}
                          </i>
                        </h5>
                      </label>
                    </div>
                  )}
                  <div className="from-group col-md-6 col-sm-12">
                    {issuesDetailsData?.issue_image && (
                      <div className="d-flex flex-row overflow-auto">
                        <div className="d-flex flex-column">
                          <label className="form-label">
                            {issuesDetailsData?.issue_image?.length > 0
                              ? "Uploaded Images"
                              : ""}
                          </label>
                          <div className="d-flex">
                            {issuesDetailsData?.issue_image?.map(
                              (value, index) =>
                                value && (
                                  <div className="m-1 p-2">
                                    <img
                                      key={index}
                                      width="100px"
                                      height="100px"
                                      src={
                                        process.env.REACT_APP_IMAGE_URL +
                                        value?.image
                                      }
                                      alt="issue-logo"
                                    />
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Row>
                {/* issue action table */}
                {issuesDetailsData?.issue_action?.length > 0 && (
                  <div className="form-group w-100">
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>Issue Actions</th>
                          <th>Status</th>
                          <th>Date & Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Issue created</td>
                          <td>Open</td>
                          <td className="text-nowrap">
                            {moment(issuesDetailsData?.created_at).format(
                              "DD-MMM-YYYY (hh:mm A)"
                            )}
                          </td>
                        </tr>
                        {issuesDetailsData?.issue_action?.map(
                          (action, index) => (
                            <tr>
                              <td>{action?.action}</td>
                              <td>
                                {issueActionStatusName(action?.issue_status)}
                              </td>
                              <td className="text-nowrap">
                                {moment(action?.created_at).format(
                                  "DD-MMM-YYYY (hh:mm A)"
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
                <div className="col-md-12 modal-footer">
                  <span
                    className="form-error"
                    style={{
                      marginRight: "32px",
                      display: isSameActionTaken ? "inline-block" : "none",
                    }}
                  >
                    Try with next issue action !
                  </span>
                  <Button
                    style={{ width: "250px" }}
                    type="button"
                    variant="secondary"
                    onClick={handleClose}
                    className=" rounded-0 border-0"
                  >
                    Close
                  </Button>
                  {user?.roles[0]?.role_name === "Sales Executive" &&
                  (issuesDetailsData?.status == 2 ||
                    issuesDetailsData?.status == 1 ||
                    issuesDetailsData?.status == 3) ? null : (
                    <Button
                      style={{ background: "#faa619", width: "250px" }}
                      type="submit"
                      // onClick={handleEditFormSubmit}
                      className=" rounded-0 border-0"
                    >
                      Update Ticket
                    </Button>
                  )}
                </div>
              </form>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default TableComponent;
