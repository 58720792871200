import React, { useEffect, useState } from "react";
import Charts from "./Charts";
import TableComponent from "../TablesSections/TableComponent";
import "./sections.scss";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";

import 'react-datepicker/dist/react-datepicker.css';
import { getMethod } from "../../API_SERVICES/ApiServices";
import {
  issuesListData,
  issuesMasterData,
  notificationsData,
  screenLoading,
} from "./ModalSlice";
import { Skeleton } from "@mui/material";
import moment, { now } from "moment";
import API from ".././Dashboards/API_Services/ServiceLayer";
import { Link } from "react-router-dom";

const Sections = () => {
  const [DateEvents, setDateEvents]=useState([])
  const [responseEndTime, setResponseEndTime] = useState();
  const [resolutionEndTime, setResolutionEndTime] = useState();
  const [formData, setFormData] = useState({
    issue_title: "",
    description: "",
    issue_category_id: "",
    role_id: "",
    property_id: "",
    user_id: "",
    corporate_id: "",
    resolved_by: "",
    resolved_at: "",
    status: "",
  });
  const [show, setShow] = useState(false);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);

  const tabs = ['Events', 'Charts',  ];
  

  const modalClicked = useSelector((state) => state?.modal?.isModalClicked);
  const editModalClicked = useSelector(
    (state) => state?.modal?.isEditModalClicked
  );
  const boxValue = useSelector((state) => state?.modal?.filteredValue);
  const filteredIssues = useSelector((state) => state?.modal?.issuesData);

  const loading = useSelector((state) => state?.modal?.loading);
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date().toISOString().split('T')[0];
  const[evntschooseDate ,setevntschooseDate]=useState(today);

  //////////////////////////////
  const calculateDelayResponses = (end, responsed, mgStart, mgEnd, index) => {
    //checking
    const copiedResponsed = new Date(JSON.parse(JSON.stringify(responsed)));
    const copiedEnd = new Date(JSON.parse(JSON.stringify(end)));
    let endDate = new Date(end);
    let responsedDate = new Date(responsed);

    const [mgStartHours, mgStartMinutes, mgStartSeconds] = mgStart.split(":");
    const [mgEndHours, mgEndMinutes, mgEndSeconds] = mgEnd.split(":");
    let isDelay;
    let delay = 0;

    const TSMgTmrwStart = new Date(
      2023,
      0,
      2,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgStart = new Date(
      2023,
      0,
      1,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgEnd = new Date(
      2023,
      0,
      1,
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );
    const cutOutTime = Math.abs(TSMgEnd - TSMgTmrwStart);
    const workingTime = Math.abs(TSMgEnd - TSMgStart);

    let endDateTs = endDate.getTime();
    let responsedTs = responsedDate.getTime();
    let dates = [];

    if (endDateTs < responsedTs) {
      //1.add dates
      let tempEndDate = new Date(endDate);
      let tempEndTs = tempEndDate.getTime();
      isDelay = true;
      dates.push(endDate);
      while (tempEndTs < responsedTs) {
        // ----------eliminate sundays
        if (responsedDate.getDay() === 0) {
          //trying to set previous day's end date
          responsedDate = new Date(
            responsedDate.getFullYear(),
            responsedDate.getMonth(),
            responsedDate.getDate() - 1,
            +mgEndHours,
            +mgEndMinutes,
            +mgEndSeconds
          );
        }
        // ----------logic for same dates
        if (
          endDate.getDate() === responsedDate.getDate() &&
          endDate.getDay() === responsedDate.getDay()
        ) {
          // ---------- above check working hours then set working end hours
          if (responsedDate.getHours() > 18) {
            const fixedDate = new Date(
              responsedDate.getFullYear(),
              responsedDate.getMonth(),
              responsedDate.getDate(),
              +mgEndHours,
              +mgEndMinutes,
              +mgEndSeconds
            );
            dates.push(fixedDate);
          } else {
            dates.push(responsedDate);
          }
          break;
        }
        // -------------adding dates to dates list
        let addDate = new Date(
          tempEndDate.getFullYear(),
          tempEndDate.getMonth(),
          tempEndDate.getDate() + 1,
          +mgEndHours,
          +mgEndMinutes,
          +mgEndSeconds
        );
        // ----------eliminate sundays
        if (addDate.getDay() === 0) {
          addDate.setDate(addDate.getDate() + 1);
        }
        if (addDate.getDate() === responsedDate.getDate()) {
          let finalDate;
          if (responsedDate.getHours() > 18) {
            finalDate = new Date(
              addDate.getFullYear(),
              addDate.getMonth(),
              addDate.getDate(),
              +mgEndHours,
              +mgEndMinutes,
              +mgEndSeconds
            );
            dates.push(finalDate);
          } else if (responsedDate.getHours() < 10) {
            //logic for fixing before working start hours
            finalDate = new Date(
              addDate.getFullYear(),
              addDate.getMonth(),
              addDate.getDate() - 1,
              +mgEndHours,
              +mgEndMinutes,
              +mgEndSeconds
            );
            //eleiminate sunday
            if (finalDate.getDay() != 0) {
              //try to eliminate fake dates
              if (
                !(
                  dates.length > 1 &&
                  dates[dates.length - 1].getDate() === finalDate.getDate()
                )
              ) {
                dates.push(finalDate);
              }
            }
          } else if (responsedDate.getHours() === 10) {
            //logic for working hours
            if (responsedDate.getMinutes() === 0) {
              //eliminate befoe 10.01 AM
              finalDate = new Date(
                addDate.getFullYear(),
                addDate.getMonth(),
                addDate.getDate() - 1,
                +mgEndHours,
                +mgEndMinutes,
                +mgEndSeconds
              );
              //eliminate sunday
              if (finalDate.getDay() === 0) {
                finalDate = new Date(
                  finalDate.setDate(finalDate.getDate() - 1)
                );
              }
              //eliminate fake dates
              if (
                !(
                  dates.length > 1 &&
                  dates[dates.length - 1].getDate() === finalDate.getDate()
                )
              ) {
                dates.push(finalDate);
              }
              //try to eliminate fake dates(backup)
              // if (
              //   dates.length > 2 &&
              //   dates[dates.length - 1].getDate() === finalDate.getDate()
              // ) {
              // } else {
              //   dates.push(finalDate);
              // }
            } else {
              //logic for working hours
              finalDate = new Date(
                addDate.getFullYear(),
                addDate.getMonth(),
                addDate.getDate(),
                responsedDate.getHours(),
                responsedDate.getMinutes(),
                responsedDate.getSeconds()
              );
              dates.push(finalDate);
            }
          } else {
            finalDate = new Date(
              addDate.getFullYear(),
              addDate.getMonth(),
              addDate.getDate(),
              responsedDate.getHours(),
              responsedDate.getMinutes(),
              responsedDate.getSeconds()
            );
            dates.push(finalDate);
          }
          break;
        } else {
          dates.push(addDate);
        }
        //logic for break while loop
        tempEndDate = addDate;
        tempEndTs = tempEndDate.getTime();
      }
    } else {
      let tempResponsed = new Date(responsed);
      let tempResponsedTs = tempResponsed.getTime();
      isDelay = false;

      while (endDateTs >= tempResponsedTs) {
        //reseting responsed date
        if (tempResponsed.getHours() < 10) {
          tempResponsed = new Date(
            tempResponsed.getFullYear(),
            tempResponsed.getMonth(),
            tempResponsed.getDate(),
            +mgStartHours,
            +mgStartMinutes,
            +mgStartSeconds
          );
        } else if (tempResponsed.getHours() > 18) {
          tempResponsed = new Date(
            tempResponsed.getFullYear(),
            tempResponsed.getMonth(),
            tempResponsed.getDate(),
            +mgEndHours,
            +mgEndMinutes,
            +mgEndSeconds
          );
        }
        //eliminate sunday
        if (tempResponsed.getDay() === 0) {
          tempResponsed = new Date(
            tempResponsed.getFullYear(),
            tempResponsed.getMonth(),
            tempResponsed.getDate() + 1,
            +mgStartHours,
            +mgStartMinutes,
            +mgStartSeconds
          );
        }
        //break loop
        if (
          tempResponsed.getDate() === endDate.getDate() &&
          tempResponsed.getDay() === endDate.getDay()
        ) {
          if (dates.length == 0) {
            dates.push(tempResponsed);
          }
          break;
        } else {
          dates.push(tempResponsed);
          tempResponsed = new Date(
            tempResponsed.getFullYear(),
            tempResponsed.getMonth(),
            tempResponsed.getDate() + 1,
            tempResponsed.getHours(),
            tempResponsed.getMinutes(),
            tempResponsed.getSeconds()
          );
          tempResponsedTs = tempResponsed.getTime();
        }
      }
      dates.push(endDate);
    }

    //calculate delay
    const firstDayStart = new Date(dates[0]);
    const firstDayEnd = new Date(
      firstDayStart.getFullYear(),
      firstDayStart.getMonth(),
      firstDayStart.getDate(),
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );
    const secondDayEnd = new Date(dates[dates.length - 1]);
    const secondDayFirst = new Date(
      secondDayEnd.getFullYear(),
      secondDayEnd.getMonth(),
      secondDayEnd.getDate(),
      +mgStartHours,
      +mgStartMinutes + mgStartSeconds
    );
    const delayFirstDay = firstDayEnd.getTime() - firstDayStart.getTime();
    const delaySecondday = secondDayEnd.getTime() - secondDayFirst.getTime();
    delay = delaySecondday + delayFirstDay;
    if (dates.length > 2) {
      let addingdelay = dates.length - 2 ? (dates.length - 2) * 3.24e7 : 3.24e7;
      delay += addingdelay;
    } else if (firstDayStart.getDate() === secondDayFirst.getDate()) {
      delay =
        new Date(dates[dates.length - 1]).getTime() -
        new Date(dates[dates.length - 2]).getTime();
    }

    //calculate days,hours,minutes
    let diffDays = Math.floor(delay / workingTime); // days
    let diffHrs = Math.floor((delay % workingTime) / 3600000); // hours
    let diffMins = Math.round(((delay % workingTime) % 3600000) / 60000); // minutes
    //make delay string
    let delayString = `${diffDays}:${diffHrs}:${diffMins}`;
    return [delayString, isDelay];
  };

  const calculateSLA = (isHours, period) => {
    if (isHours === 1) {
      const [hours, minutes] = period.split(":");
      // console.log(hours, minutes);
      return { isHours: 1, hours, minutes };
    } else if (isHours === 0) {
      return { isHours: 0, period };
    }
  };
  const calculateEndTimeResponse = (
    StartDateAndTime,
    isHours,
    responseTime,
    mgStart,
    mgEnd,
    isWorkingHours = 0
  ) => {
    const DateIssueCreated = StartDateAndTime;
    const TSIssueCreated = new Date(DateIssueCreated).getTime();
    const [mgStartHours, mgStartMinutes, mgStartSeconds] = mgStart.split(":");
    const [mgEndHours, mgEndMinutes, mgEndSeconds] = mgEnd.split(":");

    const TSMgTmrwStart = new Date(
      2023,
      0,
      2,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgStart = new Date(
      2023,
      0,
      1,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgEnd = new Date(
      2023,
      0,
      1,
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );

    const cutOutTime = Math.abs(TSMgEnd - TSMgTmrwStart);
    const workingTime = Math.abs(TSMgEnd - TSMgStart);

    const DateIssueWorkEnd = new Date(
      DateIssueCreated.getFullYear(),
      DateIssueCreated.getMonth(),
      DateIssueCreated.getDate(),
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );
    const TSIssueWorkEnd = DateIssueWorkEnd.getTime();

    if (isHours === 1) {
      console.log("Response In Hours");
      const [splittedResponseHours, splittedResponseMinutes] =
        responseTime.split(":");
      const TSIssueEnd =
        TSIssueCreated +
        (+splittedResponseHours + +splittedResponseMinutes / 60) *
          60 *
          60 *
          1000;

      if (TSIssueWorkEnd > TSIssueEnd) {
        const tempDate = new Date(TSIssueEnd);
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        setResponseEndTime(temp);

        return temp;
      } else if (TSIssueWorkEnd < TSIssueEnd) {
        const tempDate = new Date(
          TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
        );
        const temp =
          tempDate.getDay() === 0
            ? tempDate.setDate(tempDate.getDate() + 1)
            : tempDate;
        console.log("temp", temp);
        setResponseEndTime(temp);

        return temp;
      }
    } else if (isHours === 0) {
      console.log("Response in days");
      const TSIssueEnd = TSIssueCreated + workingTime;
      const DateIssueEnd = new Date(TSIssueEnd);

      if (TSIssueWorkEnd > TSIssueEnd) {
        const tempDate = new Date(TSIssueEnd);
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        setResponseEndTime(temp);

        return temp;
      } else if (TSIssueWorkEnd < TSIssueEnd) {
        const tempDate = new Date(
          TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
        );
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        setResponseEndTime(temp);
        return temp;
      }
    }
  };

  const calculateEndTimeResolution = (
    StartDateAndTime,
    isHours,
    resolutionTime,
    mgStart,
    mgEnd,
    isWorkingHours = 0
  ) => {
    const DateIssueCreated = new Date(StartDateAndTime);
    const TSIssueCreated = new Date(DateIssueCreated).getTime();
    const [mgStartHours, mgStartMinutes, mgStartSeconds] = mgStart.split(":");
    const [mgEndHours, mgEndMinutes, mgEndSeconds] = mgEnd.split(":");

    const TSMgTmrwStart = new Date(
      2023,
      0,
      2,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgStart = new Date(
      2023,
      0,
      1,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgEnd = new Date(
      2023,
      0,
      1,
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );
    const cutOutTime = Math.abs(TSMgEnd - TSMgTmrwStart);
    const workingTime = Math.abs(TSMgEnd - TSMgStart);

    const DateIssueWorkEnd = new Date(
      DateIssueCreated.getFullYear(),
      DateIssueCreated.getMonth(),
      DateIssueCreated.getDate(),
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );
    const TSIssueWorkEnd = DateIssueWorkEnd.getTime();

    if (isHours === 1) {
      // console.log("laksh", "hours");
      resolutionTime = "9:00"; //if it is working hours then working time hours should be placed here
      const [splittedResponseHours, splittedResponseMinutes] =
        resolutionTime.split(":");
      const TSIssueEnd =
        TSIssueCreated +
        (+splittedResponseHours + +splittedResponseMinutes / 60) *
          60 *
          60 *
          1000;
      const DateIssueEnd = new Date(TSIssueEnd);
      if (TSIssueWorkEnd > TSIssueEnd) {
        const tempDate = new Date(TSIssueEnd);
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        console.log("sri", tempDate.getDay());
        setResolutionEndTime(temp);
        return temp;
        // return new Date(TSIssueEnd)
      } else if (TSIssueWorkEnd < TSIssueEnd) {
        const tempDate = new Date(
          TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
        );
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        // setResolutionEndTime(temp);
        return temp;
      }
    } else if (isHours === 0) {
      // console.log("laksh", "days", resolutionTime);
      const hours_they_need = workingTime * +resolutionTime;
      let TSIssueEnd = TSIssueCreated + workingTime;
      let DateIssueEnd = new Date(TSIssueEnd);

      if (TSIssueWorkEnd > TSIssueEnd) {
        const tempDate = new Date(TSIssueEnd);
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        setResolutionEndTime(temp);

        return temp;
      } else if (TSIssueWorkEnd < TSIssueEnd) {
        for (let i = 1; i <= resolutionTime; i++) {
          let DateUpIssueCreated = new Date(
            TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
          );
          let TSUpIssueCreated = DateUpIssueCreated.getTime();
          TSIssueEnd = TSUpIssueCreated + workingTime;
          DateIssueEnd = new Date(TSIssueEnd);
        }

        const tempDate = new Date(
          TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
        );
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        // setResolutionEndTime(temp);
        return temp;
      }
    }
  };

  // console.log(calculateActualTimeDifference(new Date(2023, 7, 27, 21, 0, 0), new Date(2023, 8, 4, 11, 0, 0), '10:00:00', '19:00:00'));
  // Example usage
  const mgStart = "10:00:00";
  const mgEnd = "18:00:00";
  const end = "2023-08-28T14:30:00";
  const responsed = "2023-08-25T09:15:00";

  //////////////////////////////

  useEffect(() => {
    setIsLoading(true);
    dispatch(screenLoading(true));

    const controller = new AbortController();
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user, "user");

    getMethod("issue-management/api/v1/issue", controller.signal)
      .then((res) => {
        let apiData =
          user?.roles[0]?.role_name === "Sales Executive"
            ? res?.data?.data.filter((issue) => issue.user_id === user.id)
            : res?.data?.data;
        console.log(apiData, "Api data");
        if (res?.data?.status) {
          // ----------->new
          apiData.forEach((actEle, index) => {
            const responseSLAStatic = calculateSLA(
              actEle?.issue_category?.is_response_hour,
              actEle?.issue_category?.response_period
            );
            const resolutionSLAStatic = calculateSLA(
              actEle?.issue_category?.is_resolution_hour,
              actEle?.issue_category?.resolution_period
            );
            //SETTING STATIC  SLA
            actEle.responseSLAStatic = responseSLAStatic;
            actEle.resolutionSLAStatic = resolutionSLAStatic;
            actEle.isDelayed = false;
            //ADDING DEFAULT RESPONSED & SOLVED TIME
            actEle.responsedTime = new Date();
            actEle.solvedTime = new Date();
            actEle.isIssueAssigned = false;
            actEle?.issue_action?.forEach((ele, i, arr) => {
              if (ele.issue_status == 1) {
                const resp = calculateEndTimeResponse(
                  actEle.created_at,
                  actEle?.issue_category?.is_response_hour,
                  actEle?.issue_category?.response_period,
                  actEle?.issue_category?.mg_start_time,
                  actEle?.issue_category?.mg_end_time
                );
              } else if (ele.issue_status == 2) {
                // CHANGE RESPONSED TIME AFTER ASSIGNED
                actEle.responsedTime = new Date(ele.created_at);
                // STOP TIMER IN TABLE
                actEle.isIssueAssigned = true;
                // responsedTime = new Date(ele.created_at);
              } else if (ele.issue_status == 4) {
                // CHANGE SOLVED TIME AFTER RESOLVED
                actEle.solvedTime = new Date(ele.created_at);
              } else if (ele.issue_status == 5) {
                // RESET TO RESPONSED & SOLVED TIME AFTER RE-OPEN
                actEle.responsedTime = new Date();
                actEle.solvedTime = new Date();
                const responseArray = arr.flatMap((ele) =>
                  ele.issue_status == 2 ? ele : []
                );
                const resolutionArray = arr.flatMap((ele) =>
                  ele.issue_status == 4 ? ele : []
                );
                // logic for remaining in table
                // RESET ASSIGNED STATUS
                actEle.isIssueAssigned = false;
                // const isAssignedAfterReOpen = arr.findLast((ele) => ele.issue_status == 2);
                // const isResolvedAfterReOpen = arr.findLast((ele) => ele.issue_status == 4);

                if (responseArray[1]) {
                  actEle.responsedTime = new Date(responseArray[1].created_at);
                  actEle.isIssueAssigned = true;
                }
                if (resolutionArray[1]) {
                  actEle.solvedTime = new Date(resolutionArray[1].created_at);
                }
              }
            });
            // CALCULATING DELAY PART
            const [timeStringResponse, amIDelayResponse] =
              calculateDelayResponses(
                actEle.response_end_time,
                actEle.responsedTime,
                actEle?.issue_category?.mg_start_time,
                actEle?.issue_category?.mg_end_time,
                index
              );
            // CALCULATE TIME DELAY FOR RESPONSE
            const [timeStringResolution, amIDelayResolution] =
              calculateDelayResponses(
                actEle.resolution_end_time,
                actEle.solvedTime,
                actEle?.issue_category?.mg_start_time,
                actEle?.issue_category?.mg_end_time,
                index
              );
            // ADD CALCULATED DELAY STRINGS
            actEle.timeStringResponse = {
              isItDelay: amIDelayResponse,
              delayTime: timeStringResponse,
            };
            actEle.timeStringResolution = {
              isItDelay: amIDelayResolution,
              delayTime: timeStringResolution,
            };

            // FOR WORKING DAYS SET WHETHER DELAY OR NOT
            actEle.isDelayed =
              amIDelayResponse === true && amIDelayResolution === true
                ? true
                : false;
          });
          // ----------->new
          // ----------->old
          // apiData.map((actEle, index) => {
          //   //append one paremeter to filter delayed tickets NOTE:DELAYED TICKETS STATUS=10
          //   //response delay->response end time takes morethan issue created time
          //   actEle.isDelayed = false;

          //   actEle.responseEndTime = responseEndTime;
          //   actEle.resolutionEndTime = resolutionEndTime;
          //   actEle.responsedTime = new Date();
          //   actEle.solvedTime = new Date();
          //   actEle?.issue_action?.forEach((ele) => {
          //     if (ele.issue_status == 2) {
          //       actEle.responsedTime = new Date(ele.created_at);
          //       // responsedTime = new Date(ele.created_at);

          //     } else if (ele.issue_status == 4 || ele.issue_status == 6) {
          //       actEle.solvedTime = new Date(ele.created_at);
          //       // solvedTime = new Date(ele.created_at);
          //     }
          //   });
          //   ////////////////////////////////////////////
          //   // calculate time delay for response
          //   // console.log("bye", actEle);
          //   // const [timeStringResponse, amIDelayResponse] = calculateDelayResponse(actEle.responseEndTime,
          //   //   actEle.responsedTime,
          //   //   actEle?.issue_category?.mg_start_time,
          //   //   actEle?.issue_category?.mg_end_time,
          //   //   index)

          //   // calculate time delay for response
          //   // const [timeStringResolution, amIDelayResolution] = calculateDelayResponse(actEle.resolutionEndTime,
          //   //   actEle.solvedTime,
          //   //   actEle?.issue_category?.mg_start_time,
          //   //   actEle?.issue_category?.mg_end_time,
          //   //   index)

          //   // add properties to object
          //   // actEle.timeStringResponse = {
          //   //   isItDelay: amIDelayResponse,
          //   //   delayTime: timeStringResponse
          //   // };
          //   // actEle.timeStringResolution = {
          //   //   isItDelay: amIDelayResolution,
          //   //   delayTime: timeStringResolution
          //   // };
          //   //////////////////////////////////////////
          //   //for workingdays set whether delay or not
          //   // actEle.isDelayed = amIDelayResponse === true && amIDelayResolution === true ? true : false;

          //   //for non working days calculating delay
          //   if (
          //     new Date(actEle.responsedTime) > new Date(actEle.responseEndTime)
          //   ) {
          //     actEle.isDelayed = true;
          //   }

          //   if (
          //     new Date(actEle.solvedTime) > new Date(actEle.resolutionEndTime)
          //   ) {
          //     actEle.isDelayed = true;
          //   }
          //   //for non working days calculating delay
          //   // console.log(actEle);
          // });
          // ----------->old
          const filteredItems = apiData.filter((item) => item.status === 1);
          const totalDays = filteredItems.reduce(
            (acc, item) =>
              acc +
              (!item.issue_action.length
                ? Math.ceil(
                    Math.abs(new Date() - new Date(item.created_at)) /
                      (1000 * 60 * 60 * 24)
                  )
                : 0),
            0
          );
          const averageDays = (totalDays / filteredItems.length).toFixed(1);

          const sortedArray = apiData.sort((a, b) => b?.id - a?.id);
          let filteredData = sortedArray?.filter((data, index) => {
            if (boxValue === 1) {
              return data?.status === 1 || data?.status === 5;
            } else if (boxValue === 2) {
              return data?.status === 2;
            } else if (boxValue === 3) {
              // dispatch(screenLoading(true));
              return data?.isDelayed == true;
            } else if (boxValue === 4) {
              return data?.status === 6;
            } else if (boxValue === 5) {
              // return data?.status === 4 || data?.status === 3;
              return data?.status === 4;
            } else if (boxValue === 7) {
              return data;
            } else if (boxValue === 8) {
              return data?.status === 3;
            } else return data?.status !== 6;
          });

          dispatch(issuesListData(filteredData));
          dispatch(issuesMasterData(apiData));
          dispatch(screenLoading(false));
          setIsLoading(false);

          dispatch(
            notificationsData([
              {
                name: "Unassigned Tickets",
                count: apiData?.filter((d) => d.status == 1 || d.status == 5)
                  .length,
                value: 1,
              },
              {
                name: "Assigned Tickets",
                count: apiData?.filter((d) => d.status == 2).length,
                value: 2,
              },
              {
                name: "In-Progress Tickets",
                count: apiData?.filter((d) => d.status == 3).length,
                value: 8,
              },
              {
                name: "AVG Days Open",
                count: isNaN(averageDays) ? 0 : parseFloat(averageDays),
                //value: 6,
              },

              {
                name: "Closed Tickets",
                count: apiData?.filter((d) => d.status == 6).length,
                value: 4,
              },

              {
                name: "Confirmation Pending",
                count: apiData?.filter(
                  // (d) => d.status == 4 || d?.status == 3
                  (d) => d.status == 4
                ).length,
                value: 5,
              },
              {
                name: "Total Tickets",
                count: apiData?.length,
                value: 7,
              },
              {
                name: "Delayed Tickets",
                count: apiData?.filter((d) => d.isDelayed == true).length,
                value: 3,
              },
            ])
          );
        } else {
          alert(res?.data?.message);
          setIsLoading(false);
          dispatch(screenLoading(false));
        }
      })
      .catch((err) => {
        dispatch(issuesListData([]));
        dispatch(screenLoading(false));
        setIsLoading(false);
      });
    return () => {
      controller.abort();
    };
  }, [modalClicked, editModalClicked == false, boxValue]);
  const notiData = useSelector((state) => state?.modal?.notificationData);
  const isFullScreen = useSelector((state) => state?.modal?.fullScreenToggle);

  const floatingVersionStyles = {
    position: "fixed",
    bottom: "5px",
    right: "5px",
    padding: "3px 7px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    fontSize: "10px",
  };

  const pieChartData = [
    ["Tickets", "Reported"],
    [notiData[0]?.name, notiData[0]?.count],
    [notiData[1]?.name, notiData[1]?.count],
    [notiData[2]?.name, notiData[2]?.count],
    [notiData[3]?.name, notiData[3]?.count],
  ];

  const barChartData = [
    [
      "Element",
      "Tickets",
      { role: "style" },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
    ],
    [notiData[0]?.name, notiData[0]?.count, "blue", null],
    [notiData[1]?.name, notiData[1]?.count, "orange", null],
    [notiData[2]?.name, notiData[2]?.count, "red", null],
    [notiData[3]?.name, notiData[3]?.count, "green", null],
  ];
  console.log("f", filteredIssues);
  

  function formatTo12Hour(dateString) {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strMinutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + strMinutes + ' ' + ampm;
    return strTime;
}


  useEffect(()=>{
    DateTypeFunction()
   },[evntschooseDate])


   // event api function
   const DateTypeFunction=()=>{
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user, "user");

    let data={
      loginId:user?.id,
      // startDate: "2024-07-03",
      startDate: evntschooseDate,

    }

    API.postMethodNotoken("api/DateClusterReservations","post", data)
    .then((response) => {
          
      console.log(response);

      return response.json();
    })
    
    .then((res)=>{
      // return 
      
      console.log(res.bookings,"asdf")
      setDateEvents(res.bookings)
    })
     .catch((err)=>{console.log(err)})
  
   }

   //condition based route for event
   const GuestLocation =(data)=>{
    console.log(data.id,"cde")
    window.location.href = `/discover/booking-guest/${data.id}`;


   }

   //condition based route for event
   const BookingLocation=(data)=>{
      console.log(data.id,"cd")
    window.location.href = `/discover/booking-details/${data.id}`;

   }

   //event list ascending order sorting
  const sortedDateEvents = [...DateEvents].sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

  const [showAllVisitors, setShowAllVisitors] = useState(false);
  const [showAllMeetings, setShowAllMeetings] = useState(false);

  const handleViewMoreVisitors = () => {
    setShowAllVisitors(!showAllVisitors);
  };

  const handleViewMoreMeetings = () => {
    setShowAllMeetings(!showAllMeetings);
  };

  const visitorEvents = sortedDateEvents.filter(event => event?.guest_added_by);
  const meetingEvents = sortedDateEvents.filter(event => !event?.guest_added_by);

  const visibleVisitorEvents = showAllVisitors ? visitorEvents : visitorEvents.slice(0, 3);
  const visibleMeetingEvents = showAllMeetings ? meetingEvents : meetingEvents.slice(0, 3);


  //events and charts tabs content
  const content = [
    <>

            <div style={{ display:"flex", flexDirection:"row", alignItems:"center" ,justifyContent:"space-around",padding:0 }} >
             <div   style={{ textAlign:"center", width:"48%" ,fontSize:"16px" }} > { evntschooseDate === today ? <b>Today</b> : "  " } </div>
             <div  style={{ textAlign:"center", width:"48%" }} >      
                    <input type="date"
                      className="form-control "
                      style={{ margin:10 , width:"50%" }}
                      value={evntschooseDate}
                      autoComplete="off"
                      onChange={(e) => setevntschooseDate(e.target.value)}
                    />
                    </div>
                    </div>
                    {/* {evntschooseDate??evntschooseDate} */}
    
                    <div>
                    {visitorEvents.length === 0 && meetingEvents.length === 0 && (
        <div>No events available.</div>
      )}
      {visitorEvents.length > 0 && (
        <div>
          <b>Visitor</b>
          <ul>
            {visibleVisitorEvents.map((event, index) => (
              <li
                key={`visitor-${index}`}
                className="date_events"
                onClick={() => GuestLocation(event)}
              >
                <span>
                  {`A ${event.property_details.property_name} has a guest ${event.roles} in the slot of ${formatTo12Hour(event.start_date)}`}
                </span>
              </li>
            ))}
          </ul>
          {/* {visitorEvents.length > 3 && (
            !showAllVisitors ? (
              <span className="view_more_btn" onClick={handleViewMoreVisitors}>View More Visitors</span>
            ) : (
              <span className="view_more_btn"  onClick={handleViewMoreVisitors}>View Less Visitors</span>
            )
          )} */}
            {visitorEvents.length > 3 && (
              <Link  className="view_more_btn"  to="/visitor-management " > View More visitors </Link>
            )}
          {/* visitor-management */}
          {/* book */}
        </div>
      )}

      {meetingEvents.length > 0 && (
        <div>
          <b>Meeting Room Booking</b>
          <ul>
            {visibleMeetingEvents.map((event, index) => (
              <li
                key={`meeting-${index}`}
                className="date_events"
                onClick={() => BookingLocation(event)}
              >
                <span>
                  {`A ${event.property_details.property_name} was booked by ${event.roles} in the slot of ${formatTo12Hour(event.start_date)} to ${formatTo12Hour(event.end_date)}`}
                </span>
              </li>
            ))}
          </ul>
          {/* {meetingEvents.length > 3 && (
            !showAllMeetings ? (
              <span className="view_more_btn"  onClick={handleViewMoreMeetings}>View More Meetings</span>
            ) : (
              <span  className="view_more_btn" onClick={handleViewMoreMeetings}>View Less Meetings</span>
            )
          )} */}
          {meetingEvents.length > 3 && (
              <Link  className="view_more_btn"  to="/book " > View More booking </Link>
            )}
        </div>
      )}
    </div>
    </>,
    <>
       <Charts key={1} type={"PieChart"} data={pieChartData} /> 
            <Charts key={2} type={"BarChart"} data={barChartData} /> 
            <div style={floatingVersionStyles}>Version 1.1</div>

    </>,
  ];


  
  
  return (
    <>
      <Row className="mt-4 mx-0 p-0">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={isFullScreen ? 12 : 8}
          xl={isFullScreen ? 12 : 8}
          xxl={isFullScreen ? 12 : 8}
        >
          {loading && (
            <Container className="d-flex justify-content-center align-items-center h-100 w-100">
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </Container>
          )}

          <TableComponent
            key={1}
            tableData={filteredIssues}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </Col>
        {!isFullScreen && (
          <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
            
         
            <div className="tabs-container cct_tab">
      <div className="tabs" style={{ display:"flex", flexDirection:"row" , justifyContent:"space-between" ,padding:"0" ,borderRadius:"4px"}} >
        {tabs.map((tab, index) => (
          <div  style={{ textAlign:"center", width:"48%" ,    background: "#ffd43b",
            color: "#fff",cursor:"pointer",
            fontSize: "15px",padding:"6px",
            fontWeight: 500 , textTransform:"uppercase", borderRadius:'6px' }}
            key={index}
            className={`tab-button ${activeTab === index ? 'active' : ''}`}
            onClick={() => setActiveTab(index)}
          >
            {tab}
          </div>
        ))}
      </div>
      <div className="tab-content">
        {content[activeTab]}
      </div>
    </div>

          </Col>
        )}
      </Row>
    </>
  );
};

export default Sections;
